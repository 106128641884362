import { useEffect } from 'react';

const Footer = () => {
    
    useEffect(() => {

    }, [])

    return(
        <footer className="footerWrapper" id="footer">
            <div className="sectionWrapper">
                <div className="mainFooter">
                <div className="socialMediaWrap">
                <a href="https://github.com/tragicmj" className="socialMediaBlock">
                    <div className="socialMediaBlockInner github">
                        <div className="front">
                            <svg aria-hidden="true" viewBox="0 0 16 16" version="1.1" data-view-component="true" height="32" width="32" fill="#fff">
                                <path fillRule="evenodd" d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.013 8.013 0 0016 8c0-4.42-3.58-8-8-8z"></path>
                            </svg>
                        </div>
                        <div className="back">
                            <p>tragicmj</p>
                        </div>
                    </div>
                </a>
                <a href="https://www.linkedin.com/in/md-javed-akhtar/" className="socialMediaBlock">
                    <div className="socialMediaBlockInner linkedIn">
                        <div className="front">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 34 34">
                                <g>
                                    <path d="M34,2.5v29A2.5,2.5,0,0,1,31.5,34H2.5A2.5,2.5,0,0,1,0,31.5V2.5A2.5,2.5,0,0,1,2.5,0h29A2.5,2.5,0,0,1,34,2.5ZM10,13H5V29h5Zm.45-5.5A2.88,2.88,0,0,0,7.59,4.6H7.5a2.9,2.9,0,0,0,0,5.8h0a2.88,2.88,0,0,0,2.95-2.81ZM29,19.28c0-4.81-3.06-6.68-6.1-6.68a5.7,5.7,0,0,0-5.06,2.58H17.7V13H13V29h5V20.49a3.32,3.32,0,0,1,3-3.58h.19c1.59,0,2.77,1,2.77,3.52V29h5Z" fill="#fff"></path>
                                </g>
                            </svg>
                        </div>
                        <div className="back">
                            <p>md-javed-akhtar</p>
                        </div>
                    </div>
                </a>
                <a href="https://www.instagram.com/javed.akhtar432/" className="socialMediaBlock">
                    <div className="socialMediaBlockInner instagram">
                        <div className="front">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="18" height="18">
                              
                                <path fill="#fff" d="M11 0H5a5 5 0 0 0-5 5v6a5 5 0 0 0 5 5h6a5 5 0 0 0 5-5V5a5 5 0 0 0-5-5zm3.5 11c0 1.93-1.57 3.5-3.5 3.5H5c-1.93 0-3.5-1.57-3.5-3.5V5c0-1.93 1.57-3.5 3.5-3.5h6c1.93 0 3.5 1.57 3.5 3.5v6z"/>
                              
                                <path fill="#fff" d="M8 4a4 4 0 1 0 0 8 4 4 0 0 0 0-8zm0 6.5A2.503 2.503 0 0 1 5.5 8c0-1.379 1.122-2.5 2.5-2.5s2.5 1.121 2.5 2.5c0 1.378-1.122 2.5-2.5 2.5z"/>
                                <linearGradient id="c" x1="11.923" x2="12.677" y1="4.077" y2="3.323" gradientUnits="userSpaceOnUse">
                                    <stop offset="0" stopColor="#fff"/>
                                    <stop offset=".507" stopColor="#fff"/>
                                    <stop offset=".99" stopColor="#fff"/>
                                </linearGradient><circle cx="12.3" cy="3.7" r=".533" fill="#fff"/>
                            </svg>
                        </div>
                        <div className="back">
                            <p>javed.akhtar432</p>
                        </div>
                    </div>
                </a>
                <a href="https://medium.com/@javed.akhtar432" className="socialMediaBlock">
                    <div className="socialMediaBlockInner medium">
                        <div className="front">
                           <img src="https://res.cloudinary.com/docy5lucs/image/upload/v1632341879/pw/medium_c4wsuu.png" />
                        </div>
                        <div className="back">
                            <p>javed.akhtar432</p>
                        </div>
                    </div>
                </a>
                <a href="mailto:javed@tragicmj.com" className="socialMediaBlock">
                    <div className="socialMediaBlockInner gmail">
                        <div className="front">
                            <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 48 48" width="22" height="22">
                                <path fill="#fff" d="M45,16.2l-5,2.75l-5,4.75L35,40h7c1.657,0,3-1.343,3-3V16.2z"/>
                                <path fill="#fff" d="M3,16.2l3.614,1.71L13,23.7V40H6c-1.657,0-3-1.343-3-3V16.2z"/>
                                <polygon fill="#fff" points="35,11.2 24,19.45 13,11.2 12,17 13,23.7 24,31.95 35,23.7 36,17"/>
                                <path fill="#fff" d="M3,12.298V16.2l10,7.5V11.2L9.876,8.859C9.132,8.301,8.228,8,7.298,8h0C4.924,8,3,9.924,3,12.298z"/>
                                <path fill="#fff" d="M45,12.298V16.2l-10,7.5V11.2l3.124-2.341C38.868,8.301,39.772,8,40.702,8h0 C43.076,8,45,9.924,45,12.298z"/>
                            </svg>
                        </div>
                        <div className="back">
                            <p>javed@tragicmj.com</p>
                        </div>
                    </div>
                </a>
            </div>
                    {/* <div className="row">
                        <div className="col">
                            <h2>Let's work <span>together</span></h2>
                        </div>
                        <div className="col">
                            <button data-tf-popup="nK8BW1ts" className="typeFormBtn">Hire Me</button>
                        </div>
                    </div> */}
                   
                </div>
                <div className="absoluteFooter">
                    <p>Designed & Built By Md Javed Akhtar</p>
                </div>
            </div>
        </footer>
    )
}

export default Footer;