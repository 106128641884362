import { useEffect } from 'react';
// import Tilt from 'react-parallax-tilt';
import Tilt from 'react-tilt'
import ImageShadow from 'react-image-shadow';
import 'react-image-shadow/assets/index.css';
// import './tilt.min.js'
const PortfolioItem = require('../PortfolioDetails.json')
const PortfolioItemFive = PortfolioItem.slice(0,9)

const Portfolio = () => {
    
    useEffect(() => {
        // console.log(PortfolioItem)
    }, [])

    return(
        <section className="portfolioOverWrapper">
            <div className="sectionWrapper">
                <div className="row">
                    <div className="siteTitleWrapper">
                        <h2 className="siteTitle">Portfolio</h2>
                        <p>
                            A drop from the ocean of projects completed by me. Build for different people around the country.
                        </p>
                    </div>
                </div>
               <div className="portfolioWrapper">
                    {
                        PortfolioItemFive.map((item,index)=>{
                            return(
                                <div className="portfolioItem" key={index}>
                                    <a href={item.projectUrl} target={item.projectUrl === 'javascript:void(0)' ? '_self' : '_blank'}>
                                        <Tilt className="Tilt">
                                            <ImageShadow src={item.coverImage} />
                                        </Tilt>
                                       {
                                    //     <div className={item.type === 'App' ? 'appWrap' : 'webWrap'}>
                                    //     <span className="left"></span>
                                    //     {item.type}
                                    //     <span className="right"></span>
                                    // </div>
                                    //     <div className="content">
                                    //     <h3>{item.title}</h3>
                                    // </div>
                                       }
                                    </a>
                                </div>
                            )
                        })
                    }                 
               </div>
               <a href="javascript:void(0)" className="siteBtn">
                    <span className="present">View More</span>
                    <span className="future">Coming Soon</span>
               </a>
            </div>
        </section>
    )
}

export default Portfolio;