import React,{Suspense} from "react";

//Router
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Home from "./pages/home/index.js";


const Routes = () => {  
    return (
      <Router>
        <Suspense
        fallback={
            <div className="spin-container">
                WAIT
            </div>
        }
        >
        <Switch>
            <Route exact path="/" component={Home} />
            {/*<Route exact path="*" component={PageNotFound} />*/}
        </Switch>
        </Suspense>
      </Router>
    );
  };
  
  export default Routes;