import React, { Component, Fragment } from "react";
import Banner from '../../components/home/Banner';
import About from '../../components/home/About'
import Portfolio from "../../components/home/Portfolio";
import Blog from "../../components/home/Blog";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
// import "./index.css";
import "../../css/home.scss"

export default class homePage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    // document.title =
    //   "TragicMJ";
    return (
      <Fragment>
        <Header />
        <Banner />
        <About />
        <Portfolio />
        <Blog />
        <Footer />
      </Fragment>
      );
    }
  }