import { useEffect, Fragment,useState } from 'react';
import $ from 'jquery';

const Header = () => {
    const [activeMenuItem, setActiveMenuItem] = useState('home')

    const updateState = async (val) => {
        await setActiveMenuItem(val)
    }

    const emptyState = async () => {
        await setActiveMenuItem('home')
    }

    useEffect(() => {
        $('.toggle-menu').click (function(){
            $(this).toggleClass('active');
            $('#menu').toggleClass('open');
            $("body").toggleClass('menuActive');
        });
        $(window).on("scroll load", function() {
            $(window).scrollTop() > $(".siteHeader").outerHeight()
            ? $(".siteHeader").addClass("stickyTop")
            : $(".siteHeader").removeClass("stickyTop");
        });
    }, [])

    return(
        <Fragment>
            <header className="siteHeader">
                <div className="sectionWrapper">
                    <div className="row">
                        <div className="col logoWrapper">
                            <a href="/">
                                <img src="https://res.cloudinary.com/docy5lucs/image/upload/v1632147829/pw/logo_dmy9fj.png" />
                            </a>
                        </div>
                        <div className="col hamburger">
                            <button class="toggle-menu">
                                <span></span>
                            </button>
                        </div>
                    </div>
                </div>
            </header>
            <div id="menu" className="">
                <div className="imgWrap">
                {
                    activeMenuItem === 'home' ? (
                        <img src="https://res.cloudinary.com/docy5lucs/image/upload/v1632340198/pw/home_lsmilf.jpg" />
                    ) : activeMenuItem === 'about' ? (
                        <img src="https://res.cloudinary.com/docy5lucs/image/upload/v1632339738/pw/about_grggtq.jpg" />
                    ) : activeMenuItem === 'portfolio' ? (
                        <img src="https://res.cloudinary.com/docy5lucs/image/upload/v1632339745/pw/portfolio_d3rt66.jpg" style={{objectPosition:'bottom'}} />
                    ) : <img src="https://res.cloudinary.com/docy5lucs/image/upload/v1632173684/pw/contact_go5l6m.jpg" />
                }
                </div>
                <div className="menuWrapper">
                    <div className="menuItem">
                        <a href="/" 
                            onMouseOver={()=> updateState('home')}
                            onMouseOut={()=> emptyState()}
                        >
                            <div className="menuItemMask">
                                <span>Home</span>
                            </div>
                            <span className="fakeLink"></span>
                        </a>
                    </div>
                    <div className="menuItem">
                        <a href="#"
                            onMouseOver={()=> updateState('about')}
                            onMouseOut={()=> emptyState()}
                        >
                            <div className="menuItemMask">
                                <span>About</span>
                            </div>
                            <span className="fakeLink"></span>
                        </a>
                    </div>
                    <div className="menuItem">
                        <a href="#"
                            onMouseOver={()=> updateState('portfolio')}
                            onMouseOut={()=> emptyState()}
                        >
                            <div className="menuItemMask">
                                <span>Portfolio</span>
                            </div>
                            <span className="fakeLink"></span>
                        </a>
                    </div>
                    <div className="menuItem">
                        <a href="#"
                            onMouseOver={()=> updateState('contact')}
                            onMouseOut={()=> emptyState()}
                        >
                            <div className="menuItemMask">
                                <span>Contact</span>
                            </div>
                            <span className="fakeLink"></span>
                        </a>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Header;