import { useEffect, useState } from 'react';
import $ from 'jquery';
import './words';

const About = () => {
    // const [radius, setRadius] = useState(200);
    // const [width, setWidth] = useState();
    useEffect( async ()=>{
        console.log(window.innerWidth)
        const setWidth = window.innerWidth < 600 ? 300 : (
            window.innerWidth < 991 ? 500 : window.innerWidth/2
        )
        const radius = window.innerWidth < 600 ? 130 : 200
        const font = window.innerWidth < 600 ? 20 : 25

        var settings = {
            //height of sphere container
            height: setWidth,
            //width of sphere container
            width: setWidth,
            //radius of sphere
            radius: radius,
            //rotation speed
            speed: 2,
            //sphere rotations slower
            slower: 0.2,
            //delay between update position
            timer: 8,
            //dependence of a font size on axis Z
            fontMultiplier: font,
            //tag css stylies on mouse over
            hoverStyle: {
                border: 'none',
                color: '#0b2e6f'
            },
            //tag css stylies on mouse out
            mouseOutStyle: {
                border: '',
                color: ''
            }, 
            };
            $(document).ready(function(){
                $('#tagcloud').tagoSphere(settings);
            });
    },[])

    return ( 
        <section className="aboutUsWrapper">
                <div className="sectionWrapper">
                    <div className="about">
                        <div className="content">
                            <span className="tag">{"<h2>"}</span>

                            <h2 className="siteTitle">About Me</h2>
                            <span className="tag">{"</h2>"}</span>

                            <p>
                                I am a React Native and Frontend Web Developer based out of Kolkata, India. I have been making websites since the first year of my college and fast forward now, I have completed 50+ Websites & working on more. Published apps on Play Store & App Store.
                            </p>                            
                            <p>
                                I believe websites & apps are a person's / brand's digital identity. Just as someone never compromises on their look, they should neither compromise their app / website.
                                {/* Want to have your website / app stand out of your competitors? Click the button below and fill up a short form and I'll get back to you soon. */}
                            </p>
                            {/* <button data-tf-popup="nK8BW1ts" className="typeFormBtn">Let's Connect</button> */}
                            <img src="https://res.cloudinary.com/docy5lucs/image/upload/v1632148003/pw/signature_bdp5rb.png" className="signature" />
                        </div>
                        <div className="wordCloud">
                            <div id="tagcloud">
                                <ul>
                                    <li><a href="#">React Native</a></li>
                                    <li><a href="#">React JS</a></li>
                                    <li><a href="#">Redux</a></li>
                                    <li><a href="#">Javascript</a></li>
                                    <li><a href="#">Xcode</a></li>
                                    <li><a href="#">Mixpanel</a></li>
                                    <li><a href="#">SCSS</a></li>
                                    <li><a href="#">HTML</a></li>
                                    <li><a href="#">CSS</a></li>
                                    <li><a href="#">ES5/ES6</a></li>
                                    <li><a href="#">WordPress</a></li>
                                    <li><a href="#">Shopify</a></li>
                                    <li><a href="#">jQuery</a></li>
                                    <li><a href="#">Bootstrap</a></li>
                                    <li><a href="#">FlexBox</a></li>
                                    <li><a href="#">Tailwind CSS</a></li>
                                    <li><a href="#">Materialize CSS</a></li>
                                    <li><a href="#">OnPage SEO</a></li>
                                    <li><a href="#">NPM</a></li>
                                    <li><a href="#">Git</a></li>
                                    <li><a href="#">AMP</a></li>
                                    <li><a href="#">C</a></li>
                                    <li><a href="#">C++</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
        </section>
     );
}
 
export default About;