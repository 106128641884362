import { useEffect } from 'react';

const Blog = () => {

    return(
        <section className="blogsWrapper">
            <div className="sectionWrapper">
                <div className="row">
                    <div className="siteTitleWrapper">
                        <h2 className="siteTitle">Blog</h2>
                        <p>
                            Few non technical articles penned down by me on medium. Pen will always be mightier than the sword.
                        </p>
                    </div>
                </div>
                <div className="row">
                    <div className="column">
                        <a className="blogsBlock" href="https://medium.com/@javed.akhtar432/how-badly-are-we-dependent-on-china-95a853b532f1" target="_blank">
                            <img src="https://res.cloudinary.com/docy5lucs/image/upload/v1632340766/pw/china_lzidvw.jpg" />
                            <div className="content">
                                <h3>How badly are we dependent on China?</h3>
                                <p>
                                    Products like cell phones, telecom, power, plastic toys, critical pharma ingredients are imported in India from China accounting it for about 14 per cent of India’s imports. China has embedded itself deeply in the economy of India.
                                </p>
                            </div>
                        </a>
                    </div>
                    <div className="column">
                        <a className="blogsBlock" href="https://medium.com/@javed.akhtar432/corporate-college-life-f55d14ac193d" target="_blank">
                            <img src="https://res.cloudinary.com/docy5lucs/image/upload/v1632340806/pw/corporate-college-life_svjchg.jpg" />
                            <div className="content">
                                <h3>Corporate College Life</h3>
                                <p>
                                The title is as intriguing as I was living it, at least it will be for you to read. I was living a life both of a corporate working person and an engineering college student during my early college days. I was balancing both of these two contrasting parts of my life. At least I was trying to.
                                </p>
                            </div>
                        </a>
                    </div>
                    <div className="column">
                        <a className="blogsBlock" href="https://medium.com/@javed.akhtar432/indian-education-untitled-7740207a75cf" target="_blank">
                            <img src="https://res.cloudinary.com/docy5lucs/image/upload/v1632340782/pw/indian-education-untitled_bqbeyd.jpg" />
                            <div className="content">
                                <h3>Indian Education Untitled</h3>
                                <p>
                                The rate of education in India is so high that you would find an auto driver or a tea seller with an Engineering Degree or at least a Bachelors. No, this article is not about unemployment it’s about what comes before that.
                                </p>
                            </div>
                        </a>
                    </div>
                </div>
              </div>
        </section>
    )
}

export default Blog;